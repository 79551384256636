<template>
    <form class="filters filters--facility" @submit.prevent="submit">
        <div class="filters__inner">
            <SidebarFilterGroup v-for="(item, index) in filters" :key="index" :title="item.title" :type="item.type" :items="item.items" :selectedItems="item.selectedItems" :action="item.action" :expand="item.expand" :collapse="item.collapse" />
        </div>
        <div class="filters__footer">
            <button class="filters__btn filters__btn--apply btn btn-green btn-small" type="submit">
                Применить
                <img src="../../assets/images/svg/chevron-right_white.svg" alt="">
            </button>
            <button class="filters__btn" @click.prevent="handleClearClick">
                Сбросить фильтры
            </button>
        </div>
    </form>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import SidebarFilterGroup from '@/components/sidebar/SidebarFilterGroup.vue';
import entity from '@/helpers/FetchEntities';

export default {
    name: 'SidebarFilters',
    components: {
        SidebarFilterGroup
    },
    computed: {
        filters() {
            const productGroups = this.productGroups.filter(obj => this.selectedDirections.includes(obj.direction_id));

            // Вспомогательная функция для создания фильтров
            const createFilter = ({
                    title, type, items, action, selectedItems, expand, collapse
                }) => ({
                title,
                type,
                items,
                action,
                selectedItems: [...selectedItems],
                expand,
                collapse
            });

            // Создаем фильтры для типов партнера и признаков партнера
            const directionFilterItems = (items) => items.filter(obj => this.selectedDirections.includes(obj.direction_id));

            const filters = [
                createFilter({
                    title: 'Тип партнера',
                    type: 'checkbox',
                    items: directionFilterItems(this.partnerTypes),
                    action: this.selectPartnerType,
                    selectedItems: this.selectedPartnerTypes,
                    expand: true,
                    collapse: false
                }),
                createFilter({
                    title: 'Признак партнера',
                    type: 'checkbox',
                    items: directionFilterItems(this.partnerAttributes),
                    action: this.selectPartnerAttribute,
                    selectedItems: this.selectedPartnerAttributes,
                    expand: false,
                    collapse: false
                })
            ];

            // Добавляем фильтры для каждой группы продуктов
            productGroups.forEach(item => {
                filters.push(createFilter({
                    title: item.name,
                    type: 'checkbox',
                    items: item.products,
                    action: this.selectProduct,
                    selectedItems: this.selectedProducts,
                    expand: false,
                    collapse: false
                }));
            });

            return filters
        },
        ...mapGetters('layer', ['layer']),
        ...mapGetters('filter', ['selectedDirections', 'partnerTypes', 'partnerAttributes', 'productGroups', 'selectedPartnerTypes', 'selectedPartnerAttributes', 'selectedProducts', 'filterValues', 'filterId']),
        ...mapGetters('map', ['bounds'])
    },
    methods: {
        async submit() {
            const isAllFiltersEmpty = Object.values(this.filterValues).every(value => value === null);

            if (!isAllFiltersEmpty) {
                this.startLoading();

                const actions = {
                    setEntities: this.setEntities,
                    setFilterId: this.setFilterId,
                    setCount: this.setCount
                };

                await entity.filter(this.layer, this.bounds, this.filterValues, actions);

                this.stopLoading();
            }

            this.$router.push({ path: `/${this.layer}/filter/results?c=${this.filterId}` });
        },
        handleClearClick() {
            this.startLoading();

            this.$router.push({ path: `/${this.layer}` });
            this.clearFilter();

            this.stopLoading();
        },
        ...mapActions('filter', ['clearFilter', 'selectPartnerType', 'selectPartnerAttribute', 'selectProduct', 'setFilterId', 'setCount']),
        ...mapActions('loader', ['startLoading', 'stopLoading']),
        ...mapActions('entity', ['setEntities'])
    }
}
</script>

<style lang="scss" scoped>
.filters {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    overflow-y: hidden;

    &__inner {
        overflow-y: auto;
        margin-right: 5px;
    }

    &__inner > &__group:first-child {
        margin-top: 10px;
    }

    &__inner::-webkit-scrollbar {
        width: 5px;
    }

    &__inner::-webkit-scrollbar-thumb {
        background-color: black;
    }

    &__footer {
        margin-top: auto;
        padding: 20px 15px;
        border-top: 1px solid #BFBFBF;
        display: flex;
        justify-content: left;
        align-items: center;
        position: sticky;
        bottom: 0;
        background: #fff;
    }

    &__btn {
        font-size: 18px;
        line-height: 24px;
        color: #DC143C;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &--apply {
            color: #fff;
        }

        img {
            width: 8px;
            height: 12px;
            margin-left: 8px;
        }
    }
}

@media (max-width: 1023px) {
  .filters {
    height: calc(100vh - 180px);
  }
}
</style>
